import { markRaw } from 'vue';

import Vuetify from '@ldc_ulm/penguify';
import '@ldc_ulm/penguify/dist/vuetify.min.css';

// Dashboard/Navigation Bar
import IconNavigationMenu from '@/components/general/icons/navigation/IconMenu.vue';
import IconNavigationDashboard from '@/components/general/icons/navigation/IconDashboard.vue';
import IconNavigationAlarm from '@/components/general/icons/navigation/IconAlarm.vue';
import IconNavigationMessage from '@/components/general/icons/navigation/IconMessage.vue';
import IconNavigationReport from '@/components/general/icons/navigation/IconReport.vue';
import IconNavigationAppliance from '@/components/general/icons/navigation/IconAppliance.vue';
import IconNavigationEditor from '@/components/general/icons/navigation/IconEditor.vue';
import IconNavigationSettings from '@/components/general/icons/navigation/IconSettings.vue';
import IconNavigationHelp from '@/components/general/icons/navigation/IconHelp.vue';
import IconNavigationUser from '@/components/general/icons/navigation/IconUser.vue';

import IconSmartCoolingHub from '@/components/general/icons/IconSmartCoolingHub.vue';
import IconSmartCoolingHubWhite from '@/components/general/icons/IconSmartCoolingHubWhite.vue';
import IconAdd from '@/components/general/icons/IconAdd.vue';
import IconMove from '@/components/general/icons/IconMove.vue';

import IconAppliance from '@/components/general/icons/IconAppliance.vue';
import IconApplianceWhite from '@/components/general/icons/IconApplianceWhite.vue';
import IconApplianceBig from '@/components/general/icons/IconApplianceBig.vue';
import IconApplianceDisconnected from '@/components/general/icons/IconApplianceDisconnected.vue';

import IconInternalActionLink from '@/components/general/icons/configurable/IconInternalActionLink.vue';
import IconActionLink from '@/components/general/icons/general/IconActionLink.vue';
import IconReload from '@/components/general/icons/general/IconReload.vue';

import IconComment from '@/components/general/icons/IconComment.vue';

import IconStatusOff from '@/components/general/icons/general/IconStatusOff.vue';
import IconStatusOn from '@/components/general/icons/general/IconStatusOn.vue';

import IconNetworkHigh from '@/components/general/icons/network/IconNetworkHigh.vue';
import IconNetworkMed from '@/components/general/icons/network/IconNetworkMed.vue';
import IconNetworkLow from '@/components/general/icons/network/IconNetworkLow.vue';

import IconUser from '@/components/general/icons/IconUser.vue';
import IconUserBig from '@/components/general/icons/IconUserBig.vue';

import IconDefrost from '@/components/general/icons/IconDefrost.vue';

import IconLicense from '@/components/general/icons/IconLicense.vue';

import IconSettingsBig from '@/components/general/icons/IconSettingsBig.vue';

import IconLink from '@/components/general/icons/IconLink.vue';

import IconPrint from '@/components/general/icons/IconPrint.vue';
import IconDownload from '@/components/general/icons/IconDownload.vue';
import IconClose from '@/components/general/icons/IconClose.vue';

import IconConnectivityOk from '@/components/general/icons/IconConnectivityOk.vue';
import IconConnectivityError from '@/components/general/icons/IconConnectivityError.vue';

import IconBarChart from '@/components/general/icons/IconBarChart.vue';
import IconPieChart from '@/components/general/icons/IconPieChart.vue';

import IconService from '@/components/general/icons/IconService.vue';
import IconCompany from '@/components/general/icons/IconCompany.vue';

// Message center icons
import IconDelete from '@/components/general/icons/IconDelete.vue';
import IconDeleteDisabled from '@/components/general/icons/IconDeleteDisabled.vue';
import IconPlus from '@/components/general/icons/IconPlus.vue';
import IconPlusDisabled from '@/components/general/icons/IconPlusDisabled.vue';
import IconPublish from '@/components/general/icons/IconPublish.vue';

// Data missing display
import IconApplianceMissing from '@/components/general/icons/datamissing/IconApplianceMissing.vue';
import IconContentMissing from '@/components/general/icons/datamissing/IconContentMissing.vue';
import IconHubMissing from '@/components/general/icons/datamissing/IconHubMissing.vue';
import IconStatusMissing from '@/components/general/icons/datamissing/IconStatusMissing.vue';
import IconNoContentSad from '@/components/general/icons/datamissing/IconNoContentSad.vue';

export default {
  install(app) {
    app.use(Vuetify, {
      options: {
        customProperties: true
      },
      theme: {
        primary: '#2E8DE5',
        secondary: '#003259',
        background_color: '#f1f1f1',
        error: '#fe655c',
        bus_green: '#43A017',
        bus_red: '#E52828',
        bus_yellow: '#FFD000',
        bus_white: '#ffffff',
        bus_black: '#000000',
        bus_light_grey: '#888E94',
        bus_grey: '#737373',
        neutral: '#F0F3F6',
        neutral20: '#E5E8ED',
        neutral40: '#BDC4CA',
        bus_clay_light: '#F89939',
        bus_clay: '#EB6F24',
        bus_clay_dark: '#B0542E',
        bus_terra_light: '#D1B795',
        bus_terra: '#AA8D6D',
        bus_terra_dark: '#846554',
        bus_ruby_light: '#F56E7C',
        bus_ruby: '#E34858',
        bus_ruby_dark: '#AD0B37',
        bus_leaf_light: '#5DBF90',
        bus_leaf: '#2EA675',
        bus_leaf_dark: '#33825E',
        bus_slate_light: '#8BB2C2',
        bus_slate: '#5789A4',
        bus_slate_dark: '#3B6579'
      },
      icons: {
        // Navigation Bar
        bus_navigation_menu: markRaw({
          component: IconNavigationMenu
        }),
        bus_navigation_dashboard: markRaw({
          component: IconNavigationDashboard
        }),
        bus_navigation_alarm: markRaw({
          component: IconNavigationAlarm
        }),
        bus_navigation_message: markRaw({
          component: IconNavigationMessage
        }),
        bus_navigation_report: markRaw({
          component: IconNavigationReport
        }),
        bus_navigation_appliance: markRaw({
          component: IconNavigationAppliance
        }),
        bus_navigation_editor: markRaw({
          component: IconNavigationEditor
        }),
        bus_navigation_settings: markRaw({
          component: IconNavigationSettings
        }),
        bus_navigation_help: markRaw({
          component: IconNavigationHelp
        }),
        bus_navigation_user: markRaw({
          component: IconNavigationUser
        }),

        // Standard
        bus_add: markRaw({
          component: IconAdd
        }),
        bus_move: markRaw({
          component: IconMove
        }),

        // Connectivity page icons
        bus_connectivity_ok: markRaw({
          component: IconConnectivityOk
        }),
        bus_connectivity_error: markRaw({
          component: IconConnectivityError
        }),

        // Sub folder: general
        bus_general_gateway: markRaw({
          component: IconSmartCoolingHub
        }),
        bus_general_gateway_white: markRaw({
          component: IconSmartCoolingHubWhite
        }),
        bus_general_internal_link: markRaw({
          component: IconInternalActionLink
        }),
        bus_general_link: markRaw({
          component: IconActionLink
        }),
        bus_general_reload: markRaw({
          component: IconReload
        }),

        bus_general_status_on: markRaw({
          component: IconStatusOn
        }),
        bus_general_status_off: markRaw({
          component: IconStatusOff
        }),

        bus_comment: markRaw({
          component: IconComment
        }),

        bus_network_high: markRaw({
          component: IconNetworkHigh
        }),
        bus_network_med: markRaw({
          component: IconNetworkMed
        }),
        bus_network_low: markRaw({
          component: IconNetworkLow
        }),

        bus_appliance: markRaw({
          component: IconAppliance
        }),
        bus_appliance_disconnected: markRaw({
          component: IconApplianceDisconnected
        }),
        bus_appliance_white: markRaw({
          component: IconApplianceWhite
        }),
        bus_appliance_big: markRaw({
          component: IconApplianceBig
        }),
        bus_user: markRaw({
          component: IconUser
        }),
        bus_user_big: markRaw({
          component: IconUserBig
        }),
        bus_defrost: markRaw({
          component: IconDefrost
        }),
        bus_license: markRaw({
          component: IconLicense
        }),
        bus_settings_big: markRaw({
          component: IconSettingsBig
        }),
        bus_service: markRaw({
          component: IconService
        }),
        bus_company: markRaw({
          component: IconCompany
        }),
        bus_link: markRaw({
          component: IconLink
        }),
        bus_print: markRaw({
          component: IconPrint
        }),
        bus_download: markRaw({
          component: IconDownload
        }),
        bus_close: markRaw({
          component: IconClose
        }),

        bus_chart_bar: markRaw({
          component: IconBarChart
        }),
        bus_chart_pie: markRaw({
          component: IconPieChart
        }),

        // Message editor icons
        bus_delete: markRaw({
          component: IconDelete
        }),
        bus_delete_disabled: markRaw({
          component: IconDeleteDisabled
        }),
        bus_plus: markRaw({
          component: IconPlus
        }),
        bus_plus_disabled: markRaw({
          component: IconPlusDisabled
        }),
        bus_publish: markRaw({
          component: IconPublish
        }),

        // Data missing display
        bus_datamissing_appliance: markRaw({
          component: IconApplianceMissing
        }),
        bus_datamissing_content: markRaw({
          component: IconContentMissing
        }),
        bus_datamissing_hub: markRaw({
          component: IconHubMissing
        }),
        bus_datamissing_status: markRaw({
          component: IconStatusMissing
        }),
        bus_datamissing_no_content: markRaw({
          component: IconNoContentSad
        })
      }
    });
  }
};
